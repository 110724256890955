import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "./AboutImage"

class About extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <Img />
                                    {/* <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/> */}
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">
                                            We're a group of industry experts that eat, sleep, and breath technology. 
                                            To the modern business, Technology is critical to success and growth, but for 
                                            many companies, keeping high caliber technical expertise in-house just isn't in the cards.
                                            That's why businesses of all sizes rely on us. 
                                        </p>
                                        <p >
                                            We hold to a strict <Link className="btn-transparent rn-btn-dark" to="/about/values">code of values</Link>, but
                                            occupying a special place at the top of that list are:
                                        </p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Quality</h3>
                                                <p>Not all code is created equal. Don't settle for anything less than the best solution tailored to your specific needs.</p>
                                            </div> 
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Scale</h3>
                                                <p>We deliver solutions that always work when you need them and grow with your business. Nothing else is acceptable.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;