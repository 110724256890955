import React, { Component } from "react"

import Layout from "../components/layout/Layout"

import SliderSection from "../components/home/SliderSection"
import ServiceListSection from "../components/home/ServicesListSection"
import CounterOne from "../components/home/CounterSection"
import Testimonial from "../elements/Testimonial"
import AboutSection from "../components/home/AboutSection"
import PortfolioSection from "../components/home/PortfolioSection"
import BlogContent from "../elements/BlogContent"
import BrandTwo from "../elements/BrandTwo"
import Helmet from "../components/Helmet"

class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <>
                <Helmet pageTitle="Home" />            
            </>
        )
    }
}
export default MainDemo;
