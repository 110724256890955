import React, { Component } from "react"
import ServiceSummary from "./ServiceSummary"
import PoopEmoji from "./PoopEmoji"

class Slider extends Component {
  render() {
    return (
        <>
            <div className="slider-activation">
                {/* Start Single Slide */}
                <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    {/* <h1 className="title theme-gradient">A digital <br /> agency. </h1> */}
                                    <h1 className="title theme-gradient">No <PoopEmoji /><br /> Software. </h1>                                
                                </div>
                            </div>
                        </div>
                        {/* Start Service Area */}
                        <div className="service-wrapper service-white">
                            <ServiceSummary />
                        </div>
                        {/* End Service Area */}
                    </div>
                </div> 
                {/* End Single Slide */}
            </div>
        </>
    );
  }
}
export default Slider;